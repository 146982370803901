import React, { useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/core'
import { Analytics } from 'aws-amplify'

interface IFAQModalProps {
  isOpen: boolean
  onClose: () => void
}

const FAQModal: React.FC<IFAQModalProps> = ({ isOpen, onClose }) => {
  useEffect(() => {
    Analytics.record({
      name: 'selectContent',
      attributes: {
        content_type: 'modal_faq',
        item_id: 'faq',
      },
    })
    if (typeof window !== 'undefined' && (window as any).gtag) {
      ;(window as any).gtag('event', 'select_content', {
        content_type: 'modal_faq',
        item_id: 'faq',
      })
    }
  }, [])
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>FAQ</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Accordion>
            <AccordionItem>
              <AccordionHeader>
                <Box flex="1" textAlign="left">
                  Why are you publishing content for free?
                </Box>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel pb={4}>
                We are attempting to build a relationship with Apple and reach
                Preferred Distributor status with them. In order to reach that
                status we need to publish a certain amount of content to Apple
                platforms. As a result, we're doing what others do for money,
                for free.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionHeader>
                <Box flex="1" textAlign="left">
                  Are you submitting to other platforms (i.e. Spotify)?
                </Box>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel pb={4}>Not at this time.</AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ModalBody>

        <ModalFooter>
          <Button variantColor="gray" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FAQModal
