import React, { useState } from 'react'
import { useFormik } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  CheckboxGroup,
  Button,
  Link,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  useToast,
} from '@chakra-ui/core'
import { API, graphqlOperation } from 'aws-amplify'

import { createSeedCustomerLeadFormSubmission } from '../../graphql/mutations'

import {
  PrivacyPolicyModal,
  FAQModal,
  TermsAndConditionsModal,
} from '../../components'
import ButtonCheckbox from './ButtonCheckbox'

interface IRegistrationFormProps {
  onSubmitted: () => void
}

const RegistrationForm: React.FC<IRegistrationFormProps> = ({
  onSubmitted,
}) => {
  const toast = useToast()
  const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false)
  const [faqModalOpen, setFaqModalOpen] = useState(false)
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const formik = useFormik({
    initialValues: {
      creativeType: [],
      creativeWorks: {
        albums: undefined,
        books: undefined,
        podcasts: undefined,
        other: undefined,
      },
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: Yup.object().shape({
      creativeType: Yup.array().min(1, 'Please answer this question.'),
      creativeWorks: Yup.object().test(
        'atleast-one',
        'You must have at least one item not on Apple platforms.',
        ({ albums, books, podcasts, other }) =>
          _.sum([albums, books, podcasts, other]) > 0,
      ),
      firstName: Yup.string()
        .min(2, 'Please enter a valid first name.')
        .max(50, 'Please enter a shorter first name.')
        .required('Please enter your first name.'),
      lastName: Yup.string()
        .min(2, 'Please enter a valid last name.')
        .max(50, 'Please enter a shorter last name.')
        .required('Please enter your last name.'),
      email: Yup.string()
        .email('Please enter a valid e-mail.')
        .required('Please enter your e-mail.'),
    }),
    onSubmit: async (values) => {
      try {
        await API.graphql(
          graphqlOperation(createSeedCustomerLeadFormSubmission, {
            input: values,
          }),
        )

        onSubmitted()
      } catch (e) {
        toast({
          title: 'An error occured',
          status: 'error',
          description:
            'Some issue prevented the form submission. Send us an e-mail: contact@auroradigitalmedia.com',
          position: 'top',
        })
      } finally {
        formik.setSubmitting(false)
      }
    },
  })
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={6}>
          <Box>
            <Stack isInline width={'100%'} spacing={4}>
              <FormControl
                width={'50%'}
                isRequired
                isInvalid={formik.errors.firstName && formik.touched.firstName}
              >
                <FormLabel htmlFor={'firstName'}>First name</FormLabel>
                <Input
                  type={'text'}
                  id={'firstName'}
                  name={'firstName'}
                  placeholder={'John'}
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                width={'50%'}
                isRequired
                isInvalid={formik.errors.lastName && formik.touched.lastName}
              >
                <FormLabel htmlFor={'lastName'}>Last name</FormLabel>
                <Input
                  type={'text'}
                  id={'lastName'}
                  name={'lastName'}
                  placeholder={'Smith'}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>
          </Box>

          <Box>
            <FormControl
              isRequired
              isInvalid={formik.errors.email && formik.touched.email}
            >
              <FormLabel htmlFor={'email'}>E-mail</FormLabel>
              <Input
                type={'email'}
                name={'email'}
                id={'email'}
                autoCapitalize={'off'}
                aria-describedby="email-helper-text"
                placeholder={'john.smith@example.com'}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && formik.touched.email && (
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              )}
              <FormHelperText id="email-helper-text">
                We'll never share your email.
              </FormHelperText>
            </FormControl>
          </Box>
          <Box>
            <FormControl
              isRequired
              isInvalid={
                !!(formik.errors.creativeType && formik.touched.creativeType)
              }
            >
              <FormLabel htmlFor={'creativeType'}>What are you?</FormLabel>
              <CheckboxGroup
                id={'creativeType'}
                name={'creativeType'}
                width={'100%'}
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={{ xs: 'column', md: 'row' }}
                onChange={(values) => {
                  formik.setFieldValue('creativeType', values)
                  const removedItem = _.difference(
                    formik.values.creativeType,
                    values,
                  )[0]

                  switch (removedItem) {
                    case 'artist':
                      formik.setFieldValue('creativeWorks.albums', undefined)
                      break
                    case 'author':
                      formik.setFieldValue('creativeWorks.books', undefined)
                      break
                    case 'podcaster':
                      formik.setFieldValue('creativeWorks.podcasts', undefined)
                      break
                    case 'other':
                      formik.setFieldValue('creativeWorks.other', undefined)
                      break
                  }
                }}
                defaultValue={formik.values.creativeType}
              >
                <ButtonCheckbox value={'artist'}>An Artist</ButtonCheckbox>
                <ButtonCheckbox value={'author'}>An Author</ButtonCheckbox>
                <ButtonCheckbox value={'podcaster'}>A Podcaster</ButtonCheckbox>
                <ButtonCheckbox value={'other'}>
                  Another Creative Type
                </ButtonCheckbox>
              </CheckboxGroup>
              {formik.errors.creativeType && formik.touched.creativeType && (
                <FormErrorMessage>
                  {formik.errors.creativeType}
                </FormErrorMessage>
              )}
            </FormControl>
          </Box>

          {formik.values.creativeType.length > 0 && (
            <Box>
              <FormControl
                isRequired
                isInvalid={
                  !!(
                    formik.errors.creativeWorks && formik.touched.creativeWorks
                  )
                }
              >
                <FormLabel>
                  How much work do you have that are not on Apple platforms?
                </FormLabel>
                <Stack isInline flexDirection={{ xs: 'column', md: 'row' }}>
                  {formik.values.creativeType.includes('artist') && (
                    <FormControl>
                      <FormLabel
                        htmlFor={'creativeWorks.albums'}
                        fontSize={'sm'}
                        color={'gray.600'}
                      >
                        Albums/Mixtapes
                      </FormLabel>
                      <NumberInput
                        min={0}
                        defaultValue={formik.values.creativeWorks.albums}
                        onChange={(value) =>
                          formik.setFieldValue('creativeWorks.albums', value)
                        }
                      >
                        <NumberInputField
                          name={'creativeWorks.albums'}
                          id={'creativeWorks.albums'}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                  {formik.values.creativeType.includes('author') && (
                    <FormControl>
                      <FormLabel
                        htmlFor={'creativeWorks.books'}
                        fontSize={'sm'}
                        color={'gray.600'}
                      >
                        Books
                      </FormLabel>
                      <NumberInput
                        min={0}
                        defaultValue={formik.values.creativeWorks.books}
                        onChange={(value) =>
                          formik.setFieldValue('creativeWorks.books', value)
                        }
                      >
                        <NumberInputField
                          id={'creativeWorks.books'}
                          name={'creativeWorks.books'}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                  {formik.values.creativeType.includes('podcaster') && (
                    <FormControl>
                      <FormLabel
                        htmlFor={'creativeWorks.podcasts'}
                        fontSize={'sm'}
                        color={'gray.600'}
                      >
                        Podcasts
                      </FormLabel>
                      <NumberInput
                        min={0}
                        defaultValue={formik.values.creativeWorks.podcasts}
                        onChange={(value) =>
                          formik.setFieldValue('creativeWorks.podcasts', value)
                        }
                      >
                        <NumberInputField
                          id={'creativeWorks.podcasts'}
                          name={'creativeWorks.podcasts'}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                  {formik.values.creativeType.includes('other') && (
                    <FormControl>
                      <FormLabel
                        htmlFor={'creativeWorks'}
                        fontSize={'sm'}
                        color={'gray.600'}
                      >
                        {formik.values.creativeType.length === 1
                          ? 'Items'
                          : 'Other Items'}
                      </FormLabel>
                      <NumberInput
                        min={0}
                        defaultValue={formik.values.creativeWorks.other}
                        onChange={(value) =>
                          formik.setFieldValue('creativeWorks.other', value)
                        }
                      >
                        <NumberInputField
                          id={'creativeWorks.other'}
                          name={'creativeWorks.other'}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                </Stack>

                {formik.errors.creativeWorks &&
                  formik.touched.creativeWorks && (
                    <FormErrorMessage>
                      {formik.errors.creativeWorks}
                    </FormErrorMessage>
                  )}
              </FormControl>
            </Box>
          )}

          <Box>
            <Button
              type={'submit'}
              isLoading={formik.isValidating || formik.isSubmitting}
              width={'100%'}
              size={'lg'}
            >
              Submit
            </Button>
          </Box>
          <Stack spacing={2}>
            <Text fontSize={'sm'}>
              By submitting this form you are agreeing to our{' '}
              <Link
                textDecoration={'underline'}
                as={'button'}
                type={'button'}
                onClick={() => setTermsModalOpen(true)}
              >
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link
                textDecoration={'underline'}
                as={'button'}
                type={'button'}
                onClick={() => setPrivacyPolicyModalOpen(true)}
              >
                Privacy Policy
              </Link>
              .
            </Text>
            <Text fontSize={'sm'}>
              Questions? Send us an{' '}
              <Link
                textDecoration={'underline'}
                href={
                  'mailto:contact@auroradigitalmedia.com?subject=Contacting%20about%20AURORA%20Digital%20Media%20promotion'
                }
              >
                e-mail
              </Link>{' '}
              or check out the{' '}
              <Link
                textDecoration={'underline'}
                as={'button'}
                type={'button'}
                onClick={() => setFaqModalOpen(true)}
              >
                FAQ
              </Link>
              .
            </Text>
          </Stack>
        </Stack>
      </form>
      <PrivacyPolicyModal
        isOpen={privacyPolicyModalOpen}
        onClose={() => setPrivacyPolicyModalOpen(false)}
      />
      <FAQModal isOpen={faqModalOpen} onClose={() => setFaqModalOpen(false)} />
      <TermsAndConditionsModal
        isOpen={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      />
    </>
  )
}

export default RegistrationForm
