import React from 'react'
import { VisuallyHidden, ControlBox } from '@chakra-ui/core'

const ButtonCheckbox = React.forwardRef<any, any>((props, ref) => {
  const { isChecked, isDisabled, children, ...rest } = props
  return (
    <label>
      <VisuallyHidden
        as={'input'}
        ref={ref}
        type={'checkbox'}
        size={'24px'}
        defaultChecked={isChecked}
        {...rest}
      />
      <ControlBox
        padding={{ xs: 2, lg: 4 }}
        border={'solid'}
        borderRadius={'md'}
        borderWidth={1}
        borderColor={'gray.300'}
        userSelect={'none'}
        _checked={{ bg: 'gray.200' }}
        _hover={{ cursor: 'pointer' }}
        _checkedAndHover={{ cursor: 'pointer' }}
      >
        {children}
      </ControlBox>
    </label>
  )
})

export default ButtonCheckbox
