/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSeedCustomerLeadFormSubmission = /* GraphQL */ `
  mutation CreateSeedCustomerLeadFormSubmission(
    $input: CreateSeedCustomerLeadFormSubmissionInput!
    $condition: ModelSeedCustomerLeadFormSubmissionConditionInput
  ) {
    createSeedCustomerLeadFormSubmission(input: $input, condition: $condition) {
      id
      creativeType
      creativeWorks {
        albums
        books
        podcasts
        other
      }
      firstName
      lastName
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateSeedCustomerLeadFormSubmission = /* GraphQL */ `
  mutation UpdateSeedCustomerLeadFormSubmission(
    $input: UpdateSeedCustomerLeadFormSubmissionInput!
    $condition: ModelSeedCustomerLeadFormSubmissionConditionInput
  ) {
    updateSeedCustomerLeadFormSubmission(input: $input, condition: $condition) {
      id
      creativeType
      creativeWorks {
        albums
        books
        podcasts
        other
      }
      firstName
      lastName
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteSeedCustomerLeadFormSubmission = /* GraphQL */ `
  mutation DeleteSeedCustomerLeadFormSubmission(
    $input: DeleteSeedCustomerLeadFormSubmissionInput!
    $condition: ModelSeedCustomerLeadFormSubmissionConditionInput
  ) {
    deleteSeedCustomerLeadFormSubmission(input: $input, condition: $condition) {
      id
      creativeType
      creativeWorks {
        albums
        books
        podcasts
        other
      }
      firstName
      lastName
      email
      createdAt
      updatedAt
    }
  }
`;
