import React, { useState } from 'react'
import {
  CSSReset,
  ThemeProvider,
  theme,
  Heading,
  Flex,
  Text,
  Stack,
  Box,
} from '@chakra-ui/core'
import Helment from 'react-helmet'
import { Analytics } from 'aws-amplify'

import { RegistrationForm, FormSubmittedResultView } from '../components'

const IndexPage = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const formLabel = 'Get your content on Apple platforms for free'
  const handleFormSubmit = () => {
    setFormSubmitted(true)
    Analytics.record({
      name: 'seedCustomerLeadFormSubmit',
      attributes: {
        event_category: 'form_submit',
        event_label: formLabel,
      },
    })
    if (typeof window !== 'undefined' && (window as any).gtag) {
      ;(window as any).gtag('event', 'generate_lead', {
        event_category: 'form_submit',
        event_label: formLabel,
      })
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Helment>
        <title>Get on Apple Music for free | AURORA Digital Media</title>
        <meta
          name="description"
          content={`We're building the next generation content distribution platform and need initial users. So we're publishing content to Apple Music and other Apple platforms free of charge for a limited time.`}
        />
        <meta
          name="keywords"
          content={`publish, content distribution, get on apple music, apple podcasts, get my music on apple music, publish to apple books, distrokid, tunecore`}
        />
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.auroradigitalmedia.com",
          "name": "AURORA Digital Media",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "support@auroradigitalmedia.com",
            "contactType": "Customer Support"
          }
        }
      `}
        </script>
      </Helment>
      <CSSReset />
      <Box
        position={'fixed'}
        background={'linear-gradient(to left, #1D2B64, #F8CDDA)'}
        width={'100%'}
        height={'100%'}
        zIndex={-1}
      />
      <Flex
        height={'100%'}
        width={{ xs: '100%', md: '90%' }}
        maxWidth={1440}
        mx={'auto'}
      >
        <Stack
          flexDirection={{ xs: 'column', xl: 'row' }}
          my={{ md: 20, xl: 32 }}
          width={{ xs: '100%', xl: '1440px' }}
          rounded={{ md: 'lg' }}
          overflow={'hidden'}
          backgroundColor={'white'}
          boxShadow={'lg'}
        >
          <Stack
            width={{ xl: '40%' }}
            height={{ xl: '100%' }}
            alignItems={'center'}
            justifyContent={'center'}
            pt={{ xs: 12, lg: 24 }}
            pb={{ xs: 16, lg: 24 }}
            spacing={12}
            backgroundColor={'gray.900'}
            color={'white'}
          >
            <Stack spacing={8} width={'80%'} maxWidth={600}>
              <Heading size={'sm'} userSelect={'none'} cursor={'default'}>
                AURORA Digital Media
              </Heading>
              <Stack spacing={4}>
                <Heading color={'yellow.100'} size={'lg'}>
                  We're building the next generation content distribution
                  platform and need your help.
                </Heading>
                <Text>
                  We are looking to compete with DistroKid and TuneCore and need
                  seed content to help us reach Preferred Distributor status
                  with Apple.
                </Text>
                <Text>
                  So for a limited amount of submissions, we will be
                  distributing content to iTunes, Apple Music, Apple Podcasts,
                  Apple Books, and other Apple platforms completely free of
                  charge.
                </Text>
              </Stack>
            </Stack>
            <Box>
              <img src={'illustration.svg'} width={300} />
            </Box>
          </Stack>
          <Stack
            width={{ xs: '80%', xl: '60%' }}
            maxWidth={600}
            pt={{ xs: 8, xl: 8 }}
            pb={{ xs: 16, xl: 8 }}
            m={'auto'}
          >
            {!formSubmitted && (
              <Stack spacing={12}>
                <Stack spacing={2}>
                  <Heading size={'lg'}>{formLabel}</Heading>
                  <Text>
                    Availability is limited. This program will end once all
                    available slots are filled.
                  </Text>
                </Stack>
                <RegistrationForm onSubmitted={handleFormSubmit} />
              </Stack>
            )}
            {formSubmitted && <FormSubmittedResultView />}
          </Stack>
        </Stack>
      </Flex>
    </ThemeProvider>
  )
}

export default IndexPage
