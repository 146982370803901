import React from 'react'
import { Stack, Heading, Text, Icon } from '@chakra-ui/core'

const FormSubmittedResultView: React.FC = () => {
  return (
    <Stack spacing={4} justifyContent={'center'} alignItems={'center'}>
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
      >
        <Icon name={'check-circle'} size={'24'} color={'green.500'} />
        <Heading size={'lg'}>Submission Received</Heading>
        <Text>Thank you. We'll get back to you with more information.</Text>
      </Stack>
    </Stack>
  )
}

export default FormSubmittedResultView
